
import EventThumbnail from "@/components/gateReport/eventsTable/EventThumbnail"
import EventTimeCell from "@/components/gateReport/eventsTable/EventTimeCell"

export default {
  name: "GateReportLegacyTableRow",
  components: {
    EventThumbnail,
    EventTimeCell,
  },
  inject: [
    "event",
    "eventType",
    "eventTruckType",
    "eventCameraName",
    "eventTruckTypeId",
    "eventTypeIconStyle",
    "headers",
  ],
  data() {
    return {
      rowHeight: 100,
    }
  },
  methods: {
    updateRowHeight(height) {
      this.rowHeight = height
    },
  },
}
