
import AnprLegacyTableRow from "@evercam/dashboard/components/anpr/AnprLegacyTableRow"
import GateReportLegacyTableRow from "@evercam/dashboard/components/gateReport/eventsTable/GateReportLegacyTableRow"
import AnprBasedGateReportTableRow from "@evercam/dashboard/components/gateReport/eventsTable/AnprBasedGateReportTableRow"

import { useGateReportStore } from "@/stores/gateReport"
import { mapStores } from "pinia"

import { GateReportEventDirection } from "@evercam/shared/types"
export default {
  name: "EventsTableRowWrapper",
  components: {
    AnprLegacyTableRow,
    AnprBasedGateReportTableRow,
    GateReportLegacyTableRow,
  },
  props: {
    event: {
      type: Object,
      default: () => ({}),
    },
    headers: {
      type: Object,
      default: () => ({}),
    },
    visibleHeaders: {
      type: Array,
      default: () => [],
    },
    projectCameras: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    ...mapStores(useGateReportStore),
    eventTruckTypeId() {
      return this.event?.vehicleType
    },
    eventTruckType() {
      return this.gateReportStore.getVehicleLabel(this.eventTruckTypeId)
    },
    eventType() {
      const eventTypeMap = {
        [GateReportEventDirection.In]: "arrived_at",
        [GateReportEventDirection.Out]: "left_at",
      }

      const directionText = eventTypeMap[this.event.direction] || "unknown"

      return this.$t(`content.gate_report.${directionText}`)
    },
    eventTypeIconStyle() {
      return {
        transform: `rotate(${
          this.event.direction === GateReportEventDirection.In
            ? "0deg"
            : "180deg"
        })`,
      }
    },
    eventPlateNumber() {
      const plate = this.event?.plateNumber

      return plate?.includes("No Plate") ? null : plate
    },
    showAnprBasedGateReport() {
      return this.gateReportStore.showAnprBasedGateReport
    },
    isAnprLegacy() {
      return this.gateReportStore.isAnprLegacy
    },
    isAnprBasedProject() {
      return this.gateReportStore.isAnprBasedProject
    },
    showThumbnails() {
      return this.gateReportStore.showThumbnails
    },
    eventCameraName() {
      return this.projectCameras[this.event?.cameraExid]?.name
    },
  },
}
