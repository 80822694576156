
import EventsUtils from "@/components/gateReport/mixins/eventsUtils"

export default {
  name: "EventTimeCell",
  mixins: [EventsUtils],
  inject: ["event", "eventType", "projectCameras", "eventTypeIconStyle"],
  computed: {
    eventTime() {
      return this.event?.eventTime
    },
    eventCameraName() {
      return this.projectCameras[this.event?.cameraExid]?.name
    },
  },
}
