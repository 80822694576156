
import DateRangePickerField from "@evercam/shared/components/DateRangePickerField"

import { useGateReportStore } from "@/stores/gateReport"
import { useProjectStore } from "@/stores/project"
import { mapStores } from "pinia"
import { DownloadTypes } from "@evercam/shared/types"
const MaxDaysRangeForDirectDownload = 2

export default {
  name: "ExportOptionsDialog",
  components: { DateRangePickerField },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    fileType: {
      type: String,
      default: DownloadTypes.Pdf,
    },
  },
  data() {
    return {
      anprColumns: [
        { value: "anpr_thumbnail", text: "Anpr Thumbnail" },
        { value: "context_thumbnail", text: "Context Thumbnail" },
        { value: "event_time", text: "Event Time" },
        { value: "event_type", text: "Direction" },
        { value: "plate_number", text: "Plate Number" },
        { value: "vehicle_type", text: "Vehicle Type" },
        { value: "vehicle_make", text: "Vehicle Make" },
        { value: "vehicle_model", text: "Vehicle Model" },
        { value: "vehicle_color", text: "Vehicle Color" },
      ],
      gateReportLegacyColumns: [
        { value: "context_thumbnail", text: "Thumbnail" },
        { value: "event_time", text: "Event Time" },
        { value: "event_type", text: "Direction" },
        { value: "vehicle_type", text: "Vehicle Type" },
      ],
      selectedColumns: [],
      exportDateRange: [],
    }
  },
  computed: {
    ...mapStores(useGateReportStore, useProjectStore),
    dialog: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit("input", value)
      },
    },
    columns() {
      if (!this.showExportColumnsSelector) {
        return []
      }

      if (this.gateReportStore.showAnprBasedGateReport) {
        return this.anprColumns
      }

      return this.gateReportLegacyColumns
    },
    selectedDate() {
      return this.$moment(this.gateReportStore.selectedDate).format(
        "YYYY-MM-DD"
      )
    },
    isExportEnabled() {
      return this.exportDateRange?.length === 2
    },
    isLargeRange() {
      const [from, to] = this.exportDateRange || []

      return (
        this.fileType === DownloadTypes.Pdf &&
        from &&
        to &&
        this.$moment(to).diff(this.$moment(from), "days") >
          MaxDaysRangeForDirectDownload
      )
    },
    mediaHubLink() {
      return `${this.projectStore.projectRoute}/media-hub`
    },
    showExportColumnsSelector() {
      return (
        (this.gateReportStore.showAnprBasedGateReport ||
          this.gateReportStore.showLegacyGateReport) &&
        !this.gateReportStore.isAnprLegacy
      )
    },
  },
  watch: {
    dialog: {
      handler(value) {
        if (value) {
          this.selectedColumns = this.columns.map((item) => item.value)
          this.exportDateRange = [this.selectedDate, this.selectedDate]
        }
      },
      immediate: true,
    },
  },
  methods: {
    updateExportOptions() {
      const formatDate = (date) =>
        this.$moment
          .tz(date, this.projectStore.selectedProjectTimezone)
          .utc()
          .format("YYYY-MM-DD HH:mm:ss")

      const [fromDate, toDate] = this.exportDateRange
      const options = {
        ...(this.showExportColumnsSelector
          ? { columns: this.selectedColumns }
          : {}),
        fromDate: formatDate(`${fromDate} 00:00:00`),
        toDate: formatDate(`${toDate} 23:59:59`),
        fileType: this.fileType,
        directDownload: !this.isLargeRange,
      }

      this.$emit("export", options)
    },
  },
}
