
export default {
  name: "DateRangePickerField",
  props: {
    value: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      isDatePickerOpened: false,
      datePickerAttrs: {},
      datePickerListeners: {},
      textFieldAttrs: {},
      textFieldListeners: {},
    }
  },
  computed: {
    dateRangeDisplay() {
      const [fromDate, toDate] = this.value || []
      if (fromDate && toDate) {
        return `${this.$moment(fromDate).format("YYYY-MM-DD")} - ${this.$moment(
          toDate
        ).format("YYYY-MM-DD")}`
      }

      return ""
    },
    dateRange: {
      get() {
        return this.value
      },
      set(dates) {
        this.updateDateRange(dates)
      },
    },
  },
  watch: {
    dateRange(dates) {
      if (dates.length === 2) {
        this.isDatePickerOpened = false
      }
    },
  },
  created() {
    this.init()
  },
  methods: {
    init() {
      this.datePickerAttrs = this.$attrs?.datePickerAttrs || {}
      this.textFieldAttrs = this.$attrs?.textFieldAttrs || {}
      this.datePickerListeners = this.$listeners?.datePickerListeners || {}
      this.textFieldListeners = this.$listeners?.textFieldListeners || {}
    },
    updateDateRange(dates) {
      const [fromDate, toDate] = dates || []

      if (
        dates.length !== 2 ||
        !this.$moment(fromDate).isAfter(this.$moment(toDate))
      ) {
        this.$emit("input", dates)
      } else {
        this.$emit("input", [toDate, fromDate])
      }
    },
    clearDateRange() {
      this.$emit("input", [])
    },
  },
}
